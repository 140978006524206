header {
	position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 500;	
	height: 202px;
	background: #fff;
	transition: height .3s;

	.wrap {
		display: flex;
		width: 100%;
		align-items: center;
		transition: background .25s, box-shadow .25s;
	}

	.head {
		.container {
			height: 130px;
			display: flex;
			align-items: center;
		}
	}

	.bottom {
		background: #fff;
		height: calc(230px - 150px);
		border-top: 1px solid #e9e9e9;
		height: 72px;
		display: flex;
		align-items: center;
		transition: transform .3s, opacity .3s;

		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			display: flex;
			align-items: center;
			justify-content: space-between;

			li {
				margin: 0;
				padding: 0;

				a {
					text-decoration: none;
					font-size: 20px;
					line-height: 80%;
					color: $black;
					padding: 20px;
					margin: -20px;

					&:hover {
						color: $orange;
					}					
				}
			}
		}
	}

	.container {
		.wrap {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}

	.logo {
		width: 240px;
		height: 77px;
		transition: opacity .3s;
		transform-origin: center left;

		svg {
			width: 240px;
			height: 77px;
			transform-origin: center left;
		}

		&:hover {
			opacity: .7;
		}		
	}

	.menu {
		display: flex;
		width: 100%;
		margin: 0;
		padding: 0;
		margin-left: auto;
		margin-right: auto;
		padding-left: 20px;

		> li {
			list-style: none;
			flex: 1 1 auto;
			text-align: center;

			a {
				text-decoration: none;
				font-weight: bold;
				font-size: 18px;
				color: $black;
				position: relative;
				z-index: 101;

				&:after {
					content: '';
					position: absolute;
					left: 50%;
					right: 50%;
					bottom: -2px;
					background: $orange;
					height: 1px;
					transition: left .5s, right .5s;
				}

				&:hover {
					&:after {
						left: 0;
						right: 0;
					}
				}
			}
		}
	}

	.phone {
		font-weight: bold;
		font-size: 30px;
		color: $black;
		text-decoration: none;	
		line-height: 100%;
		white-space: nowrap;
		transition: opacity .3s;
		margin-left: 15px;
		margin-top: 3px;

		&:hover {
			opacity: .7;
		}
	}

	.menu-wrap {
		display: flex;
		align-items: center;
		flex-grow: 1;
		padding-right: 130px;
	}

	.contact {
		display: flex;
		align-items: center;
		padding-left: 80px;
	}

	.hamburger-wrap {
		display: none;
		background: $black;
		width: 60px;
		height: 60px;
		min-width: 60px;
		min-height: 60px;
		border-radius: 50%;
		align-items: center;
		justify-content: center;
		margin-left: 20px;
		transition: width .5s, height .5s;	

		.hamburger {
			position: relative;
			bottom: -2px;
		}
	}

	.mobile {
		display: none;
		position: absolute;
		left: 0;
		right: 0;
		top: 100px;
		bottom: 0;
		transition: top .5s;
		overflow-y: auto;

		&::-webkit-scrollbar {
		  width: 6px;
		}
		 
		&::-webkit-scrollbar-track {
		  background: #DDDDDD;
		}
		 
		&::-webkit-scrollbar-thumb {
		  background: $black;
		}				
	}

	.messenger {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0;
		margin: 0;
		list-style: none;

		li {
			margin-right: 12px;

			&:last-child {
				margin-right: 0;
			}

			a, svg {
				width: 34px;
				height: 34px;
			}

			a {
				svg {
					will-change: transform;
					transform-origin: center center;
					transition: transform .5s;
				}

				&:hover {
					svg {
						transform: scale(1.1);
					}
				}				
			}
		}
	}

	.btn-request {
		white-space: nowrap;
		font-size: 18px;
		padding: 17px 32px;
		margin-left: 20px;
	}

	&.is-open-menu {
		background: #fff;	
		height: 100vh !important;

		.mobile {
			display: flex;
			padding-bottom: 60px;

			.container {
				display: flex;
				flex-direction: column;

				.menu {
					flex-direction: column;
					padding: 0;

					> li {
						text-align: left;
						margin-bottom: 5px;

						&:last-child {
							margin-bottom: 0;
						}

						a {
							font-size: 27px;
						}
					}
				}
			}

			.contact {
				display: none;
			}
		}

		.wrap {
			height: 100px;

			.logo {
				width: 211px;
				height: 70px;

				svg {
					width: 211px;
					height: 70px;		
				}	
			}				
		}		
	}

	&.minify {
		box-shadow: 7px 7px 25px rgba(0,0,0,.1);
		height: 86px;
		background: #fff;

		.wrap {
			height: 86px;

			.logo {
				width: 211px;
				height: 70px;

				svg {
					width: 211px;
					height: 70px;		
				}	
			}				
		}

		.head {
			.container {
				height: 86px;
			}
		}

		.bottom {
		    transform: translateY(-100%);
		    opacity: 0;
		    z-index: -1;
		    position: relative;				
		}
	}

	&.inner {
		background: #fff;
	}			
}

footer {
	background: #000;
	color: #fff;

	.row-head {
		padding-top: 100px;
	}

	.row-bottom {
		padding-top: 100px;
		padding-bottom: 100px;
		font-size: 13px;
		line-height: 100%;
		color: #FFFFFF;
		opacity: 0.76;		
	}

	.menu-item {
		.title {
			font-weight: bold;
			font-size: 30px;
			line-height: 80%;
			color: #FFFFFF;			
			padding-bottom: 25px;
		}

		ul {
			padding: 0;
			margin: 0;

			li {
				padding: 0;
				margin: 0;				
				margin-bottom: 15px;
				list-style: none;

				&:last-child {
					margin-bottom: 0;
				}

				a {
					text-decoration: none;
					font-size: 21px;
					line-height: 100%;
					color: #FFFFFF;
					opacity: 0.76;	
					transition: opacity .35s;

					&:hover {
						opacity: 1;
					}				
				}
			}
		}
	}

	.contact {
		display: flex;
		flex-direction: column;
		text-align: right;
		margin-top: -6px;

		.head {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			margin-bottom: 20px;
		}

		.phone {
			font-weight: bold;
			font-size: 32px;
			color: $white;
			text-decoration: none;	
			line-height: 100%;
			white-space: nowrap;
			transition: opacity .3s;
			margin-left: 25px;
			margin-top: 3px;

			&:hover {
				opacity: .7;
			}
		}

		.social-wrap {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			margin-top: auto;
			margin-bottom: -126px;
			position: relative;
			z-index: 2;

			span {
				font-weight: bold;
				font-size: 15px;				
				margin-right: 10px;
			}
		}

		.messenger, .social {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0;
			margin: 0;
			list-style: none;

			li {
				margin-right: 12px;

				&:last-child {
					margin-right: 0;
				}

				a, svg {
					width: 34px;
					height: 34px;
				}

				a {
					opacity: 0.3;
					transition: opacity .35s;

					svg {
						will-change: transform;
						transform-origin: center center;
						transition: transform .5s;
					}

					&:hover {
						opacity: 1;

						svg {
							transform: scale(1.1);
						}
					}				
				}
			}
		}	

		.social {
			margin-top: -1px;
		}

		.mail-wrap {
			display: flex;
			justify-content: flex-end;
		}

		.mail {
			font-weight: bold;
			font-size: 21px;
			line-height: 100%;
			color: #FFFFFF;
			text-decoration: none;
			white-space: nowrap;	
			position: relative;		
		}	
	}
}

#send-request {
	display: none;
	width: 970px;

	.form-floating {
		margin-bottom: 20px;
	}

	input, textarea {
		font-size: 20px;
		border-left: 0;
		border-right: 0;
		border-top: 0;
		box-shadow: unset !important;
		background: none !important;
	}

	label {
		font-weight: 500;
	}

	.form-floating>label {
		padding: 0;
	}

	.form-floating>.form-control {
		padding: 0;
	}

	.jq-selectbox__select, .form-floating>label, .form-floating>.form-control:not(select):not(textarea):focus, .form-floating>.form-control:not(select):not(textarea):not(:placeholder-shown) {
		font-size: 25px;
	}

	.form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label {
		transform: scale(.7) translateY(-0.5rem) translateX(-1px);
	}		

	.form-floating>textarea.form-control:focus~label, .form-floating>textarea.form-control:not(:placeholder-shown)~label, .form-floating>textarea.form-select~label {
		transform: scale(0) translateY(-0.5rem) translateX(-1px);
	}		

	.row-bottom {
		align-items: center;
		padding-top: 30px;

		.btn {
			width: 100%;
			font-size: 20px;	
			padding: 25px 40px;
		    white-space: nowrap;			
		}

		.rules {
			label {
				font-size: 15px;
				line-height: 100%;
				color: #000000;
				opacity: 0.6;							
			}
		}
	}	
}

.s {
	&-btn {
		display: table;
		font-weight: bold;
		font-size: 20px;
		color: $black;	
		padding: 17px 52px;	
		text-decoration: none;
		position: relative;
		line-height: 100%;
		border: 0;
		background: $orange;
		text-align: center;

		span {
			position: relative;
			z-index: 2;
			line-height: 100%;
		}

		&:before {
			content: '';
			position: absolute;
			background: $black;
			left: 50%;
			right: 50%;
			top: 50%;
			bottom: 50%;
			transition: left .5s, right .5s, top .5s, bottom .5s;
		}

		&:after {
			content: '';
			position: absolute;
			background: $white;
			left: 3px;
			right: 3px;
			top: 3px;
			bottom: 3px;
			transition: left .5s, right .5s, top .5s, bottom .5s;
		}

		&:hover {
			color: #fff;

			&:before {
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
			}

			&:after {
				left: 50%;
				right: 50%;
				top: 50%;
				bottom: 50%;
			}
		}
	}

	&-btn-fill {
		display: table;
		font-weight: bold;
		font-size: 20px;
		color: $white;	
		padding: 17px 52px;	
		text-decoration: none;
		position: relative;
		line-height: 100%;
		border: 0;
		text-align: center;

		span {
			position: relative;
			z-index: 2;
			line-height: 100%;
		}

		&:before {
			content: '';
			position: absolute;
			background: $black;
			left: 50%;
			right: 50%;
			top: 50%;
			bottom: 50%;
			transition: left .5s, right .5s, top .5s, bottom .5s;
		}

		&:after {
			content: '';
			position: absolute;
			background: $orange;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			transition: left .5s, right .5s, top .5s, bottom .5s;
		}

		&:hover {
			color: #fff;

			&:before {
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
			}

			&:after {
				left: 50%;
				right: 50%;
				top: 50%;
				bottom: 50%;
			}
		}
	}

	&-btn-fill-b {
		display: table;
		font-weight: bold;
		font-size: 20px;
		color: $white;	
		padding: 17px 52px;	
		text-decoration: none;
		position: relative;
		line-height: 100%;
		border: 0;
		text-align: center;

		span {
			position: relative;
			z-index: 2;
			line-height: 100%;
		}

		&:before {
			content: '';
			position: absolute;
			background: $orange;
			left: 50%;
			right: 50%;
			top: 50%;
			bottom: 50%;
			transition: left .5s, right .5s, top .5s, bottom .5s;
		}

		&:after {
			content: '';
			position: absolute;
			background: $black;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			transition: left .5s, right .5s, top .5s, bottom .5s;
		}

		&:hover {
			color: #fff;

			&:before {
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
			}

			&:after {
				left: 50%;
				right: 50%;
				top: 50%;
				bottom: 50%;
			}
		}
	}

	&-text {
		.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
		    margin-bottom: 1rem;
		}		

		.h1, h1 {
			font-weight: bold;
			font-size: 66px !important;
			line-height: 80%;
			color: $black;			
			max-width: 65%;	
		}

		p, table {
			font-size: 20px;
			line-height: 140%;
		}

		p {
			margin-bottom: 2rem;
		}

		.h1, h1 {
		    font-size: 2em;
		    margin: 1em 0;

		    &:first-child {
		    	margin-top: 0;
		    }
		}

		table {
			width: 100%;	
			margin-bottom: 30px;	

			thead {
				td {
					border-bottom: 2px solid #000;
					border-left: 2px solid #000;
					padding: 10px;	

					&:first-child {
						border-left: 0;	
						padding-left: 0;	
					}
				}
			}

			tbody {
				td {
					border-bottom: 1px solid #C4C4C4;
					border-left: 2px solid #000;
					padding: 10px;	

					&:first-child {
						border-left: 0;	
						padding-left: 0;	
					}					
				}
			}
		}

		ol li {
			font-size: 18px;
			line-height: 125%;
			margin-bottom: 13px;

			&:last-child {
				margin-bottom: 0;
			}			
		}

		ul li {
			list-style: none;
			padding-left: 35px;
			position: relative;
			color: #272929;
			font-size: 18px;
			line-height: 125%;
			margin-bottom: 13px;

			&:last-child {
				margin-bottom: 0;
			}

			&:before {
				content: '';
				position: absolute;
				top: 10px;
				left: 0;
				background: $orange;
				width: 4px;
				height: 4px;
			}
		}

		ol li {
			color: #565656;
		}
	}

	&-title {
		font-weight: bold;
		font-size: 66px;
		line-height: 80%;
		color: $black;	
	}	

	&-title-m {
		font-weight: 350;
		font-size: 45px;
		line-height: 100%;
		color: #000000;
	}	

	&-padding {
		padding-top: 150px;
		padding-bottom: 150px;

		&-t {
			padding-top: 150px;
		}

		&-b {
			padding-bottom: 150px;
		}
	}	

	&-padding-m {
		padding-top: 80px;
		padding-bottom: 80px;

		&-t {
			padding-top: 80px;
		}

		&-b {
			padding-bottom: 80px;
		}
	}	

	&-padding-sm {
		padding-top: 50px;
		padding-bottom: 50px;

		&-t {
			padding-top: 50px;
		}

		&-b {
			padding-bottom: 50px;
		}
	}	

	&-link-line {
		text-decoration: none;
		position: relative;

		&:after {
			content: '';
			position: absolute;
			left: 50%;
			right: 50%;
			bottom: -2px;
			background: $orange;
			height: 1px;
			transition: left .5s, right .5s;
		}

		&:hover {
			&:after {
				left: 0;
				right: 0;
			}
		}
	}

	&-link-line-b {
		text-decoration: none;
		position: relative;

		&:after {
			content: '';
			position: absolute;
			left: 0%;
			right: 0%;
			bottom: -2px;
			background: $orange;
			height: 1px;
			transition: left .5s, right .5s;
		}

		&:hover {
			&:after {
				left: 50%;
				right: 50%;
			}
		}
	}	

	&-link-icon {
		display: inline-flex;
		width: auto;
		align-items: center;
		text-decoration: none;
		font-size: 21px;
		color: $black;
		font-weight: bold;

		span {
			margin-right: 10px;
		}

		svg {
			width: 20px;
			height: 20px;
			stroke-width: 5px;			
			stroke: $orange;
			transition: stroke .5s;
		}

		&:hover {
			svg {
				stroke: $black;
			}
		}
	}
}

.section {
	&-white {
		background: #fff;
	}

	&-icons {
		.col {
			.icon {
				width: 100px;
				height: 100px;
				border-radius: 50%;
				background: #D9D9D9;
				display: flex;
				align-items: center;
				justify-content: center;

				svg {
					fill: $black;
					max-width: 60%;
					max-height: 60%;
				}
			}

			b {
				display: block;
				padding: 0;
				margin-top: 20px;
				margin-bottom: 20px;
				font-size: 25px;
				line-height: 90%;
			}

			p {
				display: block;
				padding: 0;
				margin: 0;
				line-height: 120%;
				font-size: 17px;
				opacity: .8;
			}			
		}
	}

	&-category {
		.type-2 {
			display: none;
		}
	}

	&-related-category {
		position: relative;
		z-index: 2;

		.item-category-b .name {
    		white-space: unset;
    		font-size: 30px;
    		width: 100%;
		}
	}

	&-hero {
		width: 100%;
		height: 100vh;
		background: #fff;
		overflow: hidden;
		padding-top: 130px;

		.container {
			height: 100%;
			position: relative;
		}

		.swiper-slide {
			opacity: 0;
			transition: opacity 1s, transform 1s;
			transform: translateX(100%);

			&.swiper-slide-active {
				transform: translateX(0%);
				opacity: 1;
			}
		}

		.control {
			position: absolute;
			left: var(--bs-gutter-x,.75rem);
			right: var(--bs-gutter-x,.75rem);
			bottom: 65px;
			z-index: 5;
			display: flex;
			align-items: center;

			.link {
				a {
					text-decoration: none;
					font-size: 20px;
					font-weight: bold;
					color: $orange;
					transition: color .35s;

					&:hover {
						color: $black;
					}
				}
			}

			.pdf {
				svg {
					width: 30px;
					height: 30px;
					margin-right: 15px;
					fill: $orange;
					transition: fill .35s;
				}

				&:hover {
					svg {
						fill: $black;
					}
				}
			}

			.arrows {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-left: auto;

				a {
					display: flex;
					width: 60px;
					height: 60px;
					align-items: center;
					justify-content: center;

					&.swiper-button-disabled {
						opacity: .3 !important;
					}

					svg {
						width: 26px;
						height: 47px;		
						stroke-width: 3px;			
						stroke: $black;
						transition: stroke .5s;
					}

					&:not(.swiper-button-disabled):hover {
						svg {
							stroke: $orange;
						}
					}				
				}
			}

			.pagination {
				.swiper-pagination-bullet {
					width: 12px;
					height: 12px;
					border-radius: 0;
					background: #C4C4C4;
					opacity: 1 !important;
					margin-right: 15px;
					transition: background .5s;

					&:last-child {
						margin-right: 0;
					}
				}

				.swiper-pagination-bullet-active {
					background: $black;
				}
			}
		}

		.swiper-container {
			height: 100%;
			overflow: unset;
		}

		&-item {
			height: 100%;

			.row {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 100%;
			}

			.title {
				display: table;
				font-weight: bold;
				font-size: 65px;
				width: 80%;
				line-height: 80%;
				color: #000000;		
				margin-bottom: 35px;	
				margin-top: -100px;
				text-decoration: none;
			}

			.afterTitle {
				font-weight: 350;
				font-size: 37px;
				line-height: 100%;
				color: $black;			
				width: 80%;
			}

			.buttons {
				display: flex;

				.btn {
					margin-right: 30px;
					white-space: nowrap;
					position: relative;
					z-index: 2;

					&:last-child {
						margin-right: 0;
					}
				}
			}

			.btn {
				margin-top: 45px;
				position: relative;
				z-index: 2;
			}

			.image {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				transform-style: preserve-3d;
				margin-left: -330px;

				.image-wrap {
					transform: scale(1);
					transform-origin: center center;
					transition: transform .5s;
				}

				img {
					user-select: none;
					position: relative;
					z-index: 2;
					transform: translateZ(70px);
				}

				.objImage {
					position: relative;
					z-index: 2;
				}

				.objCirce {
					position: absolute;
					width: 670px;
					height: 670px;
					left: 50%;
					top: 50%;
					margin-left: -(670px/2);
					margin-top: -(670px/2);
				}

				.circe {
					display: block;
					width: 670px;
					height: 670px;
					border-radius: 50%;
					border: 78px solid #000000;
				}
			}
		}
	}

	&-request {
		box-shadow: 45px 45px 150px rgba(0, 0, 0, 0.25);
		background: #fff;

		.afterTitle {
			font-weight: 350;
			font-size: 35px;
			line-height: 100%;
			color: #000000;			
		}

		.form {
			.this {
				display: flex;
				justify-content: space-between;

				input[type="text"] {
					border: 0;
					border-bottom: 2px #C4C4C4 solid;
					height: 59px;
					font-weight: bold;
					font-size: 45px;	
					letter-spacing: 0.03em;
					color: #000;
					width: 100%;
					padding-bottom: 14px;
					text-align: center;
					text-align: left !important;
					margin-right: 50px;
					
					&::placeholder {
						color: #C4C4C4;
					}	

					&.error {
						border-bottom: 2px #E74623 solid;
					}						
				}

				.btn {
					height: 59px;
					white-space: nowrap;
				}				
			}

			.rules {
				font-size: 15px;
				line-height: 80%;
				color: #000000;
				margin-top: 21px;
				display: flex;
				align-items: center;
				padding-left: 0;

				label {
					opacity: 0.6;
				}
			}	

			.form-check-input[type=checkbox] {
			    display: inline-block;
			    float: none;
			    margin: 0;	
			    margin-right: 15px;			
			}		
		}
	}

	&-page {
		background: #fff;
	}

	&-calc {
		box-shadow: 45px 45px 150px rgba(0, 0, 0, 0.25);
		background: #fff;

		.form-floating {
			padding-bottom: 10px;
		}

		.row-result {
			.title {
				padding-top: 50px;	
				padding-bottom: 30px;	
				font-size: 30px;	
			}
		}

		.row-bottom {
			.wrap {
				padding-top: 30px;
				display: flex;
				justify-content: space-between;
				align-items: center;						
			}

			.rules {
				margin-left: auto;
				padding-left: 50px;
				margin-bottom: 0;

				label {
					font-size: 15px;
					line-height: 100%;
					color: #000000;
					opacity: 0.6;							
				}
			}
		}		

		.slide {
			display: flex;
			align-items: center;
			margin-bottom: 50px;

			.wrap {
				width: calc(100% - 520px);
				margin-top: -13px;
			}

			p {
				width: 520px;
				font-weight: 350;
				font-size: 27px;
				line-height: 100%;
				color: #000000;
			}

			.range-slider {
				border: 0;
				background: #000;
				border-radius: 8px;
				height: 5px;
				font-family: $font-family-base;

				.ui-slider-range {
					background: #C4C4C4;
				}

				.min, .max {
					position: absolute;
					bottom: -36px;
					font-size: 16px;
					line-height: 100%;
					color: #000;
					opacity: .5;
					padding-top: 14px;					

					&:before {
						content: '';
						position: absolute;
						width: 1px;
						height: 9px;
						background: #C4C4C4;
						top: 0;
					}
				}

				.ui-slider-handle {
					width: 19px;
					height: 19px;					
					background: #000000;
					border-radius: 50%;
					border: 0;
					display: flex;
					align-items: center;
					justify-content: center;
				    top: -7px;
				    margin-left: -(19px/2);					

					span {
						font-weight: bold;
						font-size: 18px;
						line-height: 100%;
						color: #000000;						
						margin-top: -48px;
						white-space: nowrap;
					}
				}

				.min {
					left: 0;
					text-align: left;

					&:before {
						left: 0;
					}					
				}

				.max {
					right: 0;
					text-align: right;

					&:before {
						right: 0;
					}
				}
			}
		}

		.s-btn-fill {
			font-size: 22px;
			padding: 25px 55px;
			white-space: nowrap;
		}

		.jq-selectbox__select, .form-floating>label, .form-floating>.form-control:not(select):not(textarea):focus, .form-floating>.form-control:not(select):not(textarea):not(:placeholder-shown) {
			font-size: 25px;
		}

		.form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label {
			transform: scale(.7) translateY(-0.5rem) translateX(-1px);
		}
	}

	&-category-white {
		background: #fff;

		.item-category-b {
			background: #f1f1f1;
		}
	}	

	&-reviews-white {
		background: #fff;

		.item-review {
			background: #f1f1f1;
		}		
	}

	&-dec {
		border-top: 1px solid #C4C4C4;
	}	

	&-case {
		margin-top: -40px;

		.item-case {
			margin-top: 40px;
		}
	}	

	&-related {
		.item-category-b {
			background: #f1f1f1;
		}
	}	

	&-images {
		width: 100%;
		overflow: hidden;
		box-shadow: 45px 45px 150px rgba(0, 0, 0, 0.25);
		background: #fff;

		.swiper-container {
			overflow: unset;

			.swiper-slide {
				opacity: 0;
				transform: scale(0);
				transform-origin: center center;
				transition: opacity .5s, transform .5s;

				&.swiper-slide-active, &.swiper-slide-active + .swiper-slide, &.swiper-slide-active + .swiper-slide + .swiper-slide {
					opacity: 1;
					transform: scale(1);
				}
			}
		}

		.row-head {
			.wrap {
				display: flex;
				align-items: center;
				justify-content: space-between;				
			}
		}
	}	

	&-brandbook {
		width: 100%;
		overflow: hidden;
		box-shadow: 45px 45px 150px rgba(0, 0, 0, 0.25);
		background: #fff;

		.swiper-container {
			overflow: unset;

			.swiper-slide {
				opacity: 0;
				transform: scale(0);
				transform-origin: center center;
				transition: opacity .5s, transform .5s;

				img {
					max-width: 100%;
					box-shadow: 35px 35px 110px rgba(0,0,0,0.1);
				}

				&.swiper-slide-active, &.swiper-slide-active + .swiper-slide {
					opacity: 1;
					transform: scale(1);
				}
			}
		}

		.row-head {
			.wrap {
				display: flex;
				align-items: center;
				justify-content: space-between;				
			}
		}
	}	

	&-works {
		width: 100%;
		overflow: hidden;

		.swiper-buttons .button-next i, .swiper-buttons .button-prev i {
			background: #f3f3f3;
		}

		.swiper-buttons .button-next:not(.swiper-button-disabled):hover i, .swiper-buttons .button-prev:not(.swiper-button-disabled):hover i {
			background: #fff;
		}

		.swiper-container {
			overflow: unset;

			.swiper-slide {
				opacity: 0;
				transform: scale(0);
				transform-origin: center center;
				transition: opacity .5s, transform .5s;

				&.swiper-slide-active, &.swiper-slide-active + .swiper-slide {
					opacity: 1;
					transform: scale(1);
				}
			}
		}
	}	

	&-review {
		.wrap {
			background: #fff;
			padding: 70px 100px;
		}

		.cnt {
			margin-bottom: 30px;		
		}

		.s-text {
			p {
				font-size: 17px;
				line-height: 130%;
				color: #000000;
				opacity: 0.7;

				&:last-child {
					padding-bottom: 0;
					margin-bottom: 0;
				}							
			}
		}

		.data {
			display: flex;
			align-items: center;

			.person {
				font-size: 25px;
				font-weight: bold;
				color: #000000;	
				line-height: 100%;		
				margin-right: 20px;		
			}

			.date {
				font-size: 15px;
				color: #000000;
				opacity: 0.7;	
				line-height: 100%;	
				margin-top: 5px;			
			}
		}		
	}	

	&-team {
		overflow: hidden;
		width: 100%;

		.swiper-container {
			overflow: unset;
		}

		.swiper-wrap {
			.swiper-buttons .button-next i, .swiper-buttons .button-prev i {
				margin-top: -100px;
			}
		}

		.item {
			display: flex;
			flex-direction: column;

			img {
				max-width: 100%;
				min-width: 100%;
				margin-bottom: 40px;
				border-radius: 50%;
			}

			b {
				font-size: 27px;
				font-weight: bold;
				padding: 0;
				margin: 0;
				color: $black;
			}

			p {
				font-size: 20px;
				color: $black;
				line-height: 100%;
				opacity: .8;
			}
		}
	}
}

.item {
	&-category, &-category-b {
		background: #fff;
		padding: 80px;
		position: relative;
		text-decoration: none;
		height: 310px;
		display: flex;
		align-items: flex-end;

		.circe-wrap {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			overflow: hidden;
		}

		.circe {
			position: absolute;
			width: 552px;
			height: 552px;
			border-radius: 50%;
			left: 389px;
			top: 67px;
			border: 78px solid #000000;
			transform: translate3d(-150px, -150px, 0);
			opacity: 0;
			transition: transform 1s, opacity .65s;
			transition-delay: .25s;
		}

		.image {
			position: absolute;
			right: -90px;
			top: -120px;
			bottom: 0;
			z-index: 2;
		}

		.name {
			position: relative;
			color: $black;
			font-weight: bold;
			font-size: 35px;
			line-height: 80%;	
			transition-delay: .25s;		
			max-width: 50%;

			&:after {
				content: '';
				position: absolute;
				left: 0%;
				right: 100%;
				bottom: -10px;
				background: $orange;
				height: 2px;
				transition: left .5s, right .5s;
				transition-delay: .25s;
			}		
		}

		&:hover {
			.name:after {
				left: 0;
				right: 0;
			}

			.circe {
				opacity: 1;
				transform: translate3d(0, 0, 0);
			}
		}			
	}

	&-category-b {
		flex-direction: column;
		align-items: flex-start;
		padding: 70px;
		overflow: hidden;

		.circe {
			width: 500px;
			height: 500px;
			left: unset;
			top: unset;
			right: -200px;
			bottom: -200px;
		}

		.image {
			position: absolute;
			right: -70px;
			bottom: -53px;
			top: unset;
			z-index: 2;
		}

		.name {
			display: table;
			width: 1%;
			white-space: nowrap;	
		}			
	}

	&-review {
		background: #fff;
		padding: 50px;

		.tag {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			white-space: nowrap;
			padding: 10px 20px;
			color: #fff;
			text-decoration: none;
			font-weight: bold;
			font-size: 15px;
			border-radius: 3px;
			overflow: hidden;
			position: relative;

			&:before {
				content: '';
				position: absolute;
				left: -1px;
				right: -1px;
				top: -1px;
				bottom: -1px;
				background: #000;
				transition: left .5s, right .5s, top .5s, bottom .5s;
			}

			&:after {
				content: '';
				position: absolute;
				left: 50%;
				right: 50%;
				top: 50%;
				bottom: 50%;
				background: $orange;
				transition: left .5s, right .5s, top .5s, bottom .5s;
			}

			span {
				position: relative;
				z-index: 2;
			}

			&:hover {
				&:before {
					left: 50%;
					right: 50%;
					top: 50%;
					bottom: 50%;					
				}

				&:after {
					left: -1px;
					right: -1px;
					top: -1px;
					bottom: -1px;					
				}
			}
		}

		.view {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			font-weight: 800;
			font-size: 16px;
			color: #000000;
			line-height: 100%;
			opacity: 0.7;	
			text-decoration: none;
			transition: opacity .5s;

			svg {
				width: 22px;
				height: 22px;
				margin-right: 7px;
				fill: #000;
				transition: fill .35s;
			}		

			&:hover {
				opacity: 1;

				svg {
					fill: $orange;
				}
			}
		}

		.head {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		.cnt {
			margin-bottom: 30px;
			padding-top: 0;
		}

		.s-text {
			p {
				font-size: 17px;
				line-height: 130%;
				color: #000000;
				opacity: 0.7;

				&:last-child {
					padding-bottom: 0;
					margin-bottom: 0;
				}							
			}
		}

		.data {
			display: flex;
			flex-direction: column;

			.person {
				font-size: 16px;
				color: #000000;	
				line-height: 100%;				
				opacity: .8;
			}

			.company {
				font-size: 18px;
				color: #000000;	
				line-height: 100%;	
				margin-bottom: 6px;			
			}
		}

		.image, .video {
			width: 100%;
			display: block;
			height: 185px;
			max-height: 185px;
			position: relative;
			overflow: hidden;

			img {
				transform-origin: center;
				transition: transform .35s;
				transition-delay: .15s;
			}

			&:before {
				content: '';
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				z-index: 2;
				border: 1px #C4C4C4 solid;
			}

			&:after {
				content: '';
				position: absolute;
				left: 1px;
				right: 1px;
				top: 1px;
				bottom: 1px;
				z-index: 3;
				background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
			}

			.icon {
				position: absolute;
				left: 50%;
				top: 50%;
				width: 65px;
				height: 65px;
				margin-left: -(65px/2);
				margin-top: -(65px/2);
				z-index: 4;
				transform-origin: center center;
				transition: transform .5s;
				will-change: transform;
				transition-delay: .1s;

				svg {
					width: 65px;
					height: 65px;	
					fill: #4D4D4D;
					transition: fill .4s;			
				}
			}

			img {
				max-width: 100%;
				min-width: 100%;
			}

			&:hover {
				img {
					transform: scale(1.05);
				}

				.icon {
					transform: scale(1.15);

					svg {
						fill: $orange;
					}					
				}
			}
		}		

		.video {
			position: relative;

			.video-image {
				background-size: cover;
				background-position: center center;
				filter: grayscale(100%);
				opacity: .65;	
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;			
			}

			&:after {
				opacity: .65;
			}
		}		
	}

	&-advantage {
		strong {
			font-weight: normal;
			font-size: 22px;
			line-height: 120%;
			color: #000000;	
			margin-bottom: 25px;
			display: table;
			width: 70%;
		}	

		p {
			padding: 0;
			margin: 0;
			font-weight: 350;
			font-size: 20px;
			line-height: 120%;
			color: #000000;			
		}
	}

	&-box_category {
		.wrap {
			display: flex;
			width: 100%;
		}

		.left, .right {
			width: 50%;
		}

		.image {
			background: #F5F5F5;
			min-height: 650px;
			position: relative;
			overflow: hidden;
			display: flex;
			align-items: center;
			justify-content: center;

			&-bottom {
				align-items: flex-end;
			}

			img {
				filter: grayscale(100%);
				transition: filter 1s;
				position: relative;
				z-index: 2;
				max-width: 100%;
				min-width: 100%;
			}

			.circe-wrap {
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				transition: transform .7s;
				transform-origin: center center;
			}

			.circe {
				position: absolute;
				width: 564px;
				height: 564px;
				left: 50%;
				top: 50%;
				margin-left: -(564px/2);
				margin-top: -(564px/2);
				border-radius: 50%;
				border: 78px solid $orange;		
				transition: border .35s;	
			}

			&.is-hover {
				img {
					filter: grayscale(0%);		
				}

				.circe {
					border: 78px solid $black;
				}

				.circe-wrap {
					transform: scale(1.1);
				}
			}
		}

		.cnt {
			padding: 210px;
			align-items: center;
			justify-content: center;
			background: #fff;

			&.left {
				padding-left: calc(((100% - 1510px) / 2) + var(--bs-gutter-x,.75rem));
			}

			&.right {
				padding-right: calc(((100% - 1510px) / 2) + var(--bs-gutter-x,.75rem));
			}
		}

		.cnt-wrap {
			.title {
				font-weight: bold;
				font-size: 37px;
				line-height: 100%;
				color: #000000;				
			}

			p {
				margin: 0;
				padding: 0;
				font-weight: 350;
				font-size: 22px;
				line-height: 120%;
				color: #000000;				
				padding-top: 30px;
				padding-bottom: 50px;
			}

			.buttons {
				display: inline-flex;

				.btn:first-child {
					margin-right: 25px;
				}
			}
		}
	}

	&-case {
		text-decoration: none;

		.image {
			img {
				user-select: none;
				transform-origin: center center;
				transition: transform .5s;
				max-width: 100%;
			}
		}

		.data {
			padding-top: 40px;
			display: flex;
			flex-direction: column;

			strong {
				font-weight: bold;
				font-size: 36px;
				line-height: 100%;
				color: #000000;		
				transition: color .35s;		
			}

			span {
				font-weight: 350;
				font-size: 25px;
				line-height: 120%;
				color: #000000;				
			}
		}

		&:hover {
			img {
				transform: scale(1.05) rotate(-1deg);
			}

			strong {
				color: $orange;
			}
		}
	}
}

.page-inner {
	padding-top: 202px;

	.breadcrumbs.white {
		padding-top: 300px;
		margin-top: -230px;
	}
}

.page-case {
	.btn {
		white-space: nowrap;
	}

	.case-head {
		.site {
			display: inline-flex;
			align-items: center;
			text-decoration: none;
			font-weight: bold;
			font-size: 25px;
			color: #000000;	
			margin-left: 30px;

			&.hide {
				display: none;
			}

			span {
				position: relative;

				&:after {
					content: '';
					position: absolute;
					left: 0%;
					right: 0%;
					bottom: 2px;
					background: $orange;
					height: 1px;
					transition: left .5s, right .5s;
				}

				&:hover {
					&:after {
						left: 0;
						right: 100%;
					}
				}
			}

			svg {
				width: 33px;
				height: 33px;
				margin-left: 5px;
				stroke-width: 2px;
				stroke-linecap: round; 
				stroke-linejoin: round;
				stroke: $orange;
				transition: stroke .35s;
			}	

			&:hover {
				svg {
					stroke: $black;
				}
			}	
		}

		.row {
			.text {
				p {
					font-weight: 350;
					font-size: 25px;
					line-height: 120%;
					color: #000000;					
				}
			}

			strong {
				display: block;
				font-weight: bold;
				font-size: 32px;
				line-height: 80%;
				color: #000000;
				padding-bottom: 35px;
			}

			p {
				font-weight: 350;
				font-size: 18px;
				line-height: 120%;
				color: #000000;

				&:last-child {
					padding-bottom: 0;
					margin-bottom: 0;
				}						
			}
		}		
	}

	.case-dec {
		border-top: 1px solid #C4C4C4;
	}

	.case-logotype {
		.white {
			background: #fff;
		}

		.this {
			display: flex;
			align-items: center;
			justify-content: center;
			padding-left: 100px;
			padding-right: 100px;

			img {
				max-width: 100%;
			}
		}
	}

	.case-design {
		.item {
			img {
				max-width: 100%;
				transform-origin: center center;				
				transition: transform .5s;
			}

			&:hover {
				img {
					transform: scale(1.05) rotate(-1deg);
				}
			}
		}
	}
}

.page-contact {
	.contact {
		display: flex;

		.left {
			padding-left: calc(((100% - 1510px) / 2) + var(--bs-gutter-x,.75rem));
			padding-top: 100px;
			padding-bottom: 125px;
			padding-right: 50px;
			background: #fff;

			.title {
				font-weight: bold;
				font-size: 45px;
				line-height: 80%;
				color: #000000;		
				margin-bottom: 20px;			
			}

			.head {
				p {
					padding: 0;
					margin: 0;
					font-size: 20px;
					line-height: 135%;
					color: #000000;
				}
			}

			.this {
				display: flex;
				justify-content: space-between;
				padding-top: 80px;
				padding-bottom: 80px;
				border-bottom: 1px solid #C4C4C4;

				.contact-wrap, .mail-wrap {
					display: inline-flex;
					align-items: center;
					justify-content: center;
				}

				.messenger {
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 0;
					margin: 0;
					list-style: none;

					li {
						margin-right: 15px;

						&:last-child {
							margin-right: 0;
						}

						a, svg {
							width: 44px;
							height: 44px;
						}

						a {
							svg {
								will-change: transform;
								transform-origin: center center;
								transition: transform .5s;
							}

							&:hover {
								svg {
									transform: scale(1.1);
								}
							}				
						}
					}
				}

				.phone {
					font-weight: bold;
					font-size: 39px;
					color: $black;
					text-decoration: none;	
					line-height: 100%;
					white-space: nowrap;
					transition: opacity .3s;
					margin-top: 5px;
					margin-right: 15px;

					&:hover {
						opacity: .7;
					}
				}	

				.mail {
					font-weight: bold;
					font-size: 25px;
					line-height: 100%;
					color: #000000;					
				}			
			}

			.feedback {
				padding-top: 80px;

				.form-floating {
					padding-bottom: 10px;
				}

				.row-bottom {
					.wrap {
						padding-top: 30px;
						display: flex;
						justify-content: space-between;
						align-items: center;						
					}

					.btn {
						width: 200px;
					}

					.rules {
						width: calc(100% - 200px);
						padding-left: 50px;
						margin-bottom: 0;

						label {
							font-size: 15px;
							line-height: 100%;
							color: #000000;
							opacity: 0.6;							
						}
					}
				}
			}

			.map-wrap {
				display: none;	
				position: relative;	
				width: 100%;
				height: 370px;
				background: var(--bs-body-bg);
				margin-top: 60px;
			}
		}

		.left, .right {
			width: 50%;
			background: #fff;
		}

		.right {
			position: relative;

			.map {
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				height: 100vh;
				max-height: 600px;
				min-height: 600px;
				background: var(--bs-body-bg);
			}
		}

		.form-control {
			border-left: 0;
			border-right: 0;
			border-top: 0;
			background: none !important;
			box-shadow: unset !important;
			padding: 0;
			padding-top: 18px;
			font-size: 20px;			
		}

		.form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label {
		    transform: scale(.85) translateY(-0.5rem) translateX(-2.2px);
		}

		.form-floating>textarea.form-control:focus~label, .form-floating>textarea.form-control:not(:placeholder-shown)~label, .form-floating>textarea.form-select~label {
		    transform: scale(0) translateY(-0.5rem) translateX(-2.2px);
		}

		.form-floating>label {
			padding: 0;
			font-size: 20px;
			opacity: .3;
			padding-top: 10px;
		}
	}
}

.breadcrumbs {
	&.white {
		background: #fff;
		padding-top: 30px;
	}

	.wrap {
		display: flex;
		justify-content: space-between;
		align-items: center;		
	}

	.tags {
		padding: 0;
		margin: 0;
		display: inline-flex;
		align-items: center;
		margin-left: 40px;
		margin-top: -17px;

		&.hide {
			display: none;
		}

		li {
			list-style: none;
			padding: 0;
			margin: 0;
			margin-right: 30px;
			padding-top: 20px;

			&:last-child {
				margin-right: 0;
			}

			a {
				font-size: 20px;
				line-height: 100%;
				color: #000000;	
				text-decoration: none;
				position: relative;
				opacity: 0.6;
				white-space: nowrap;
				transition: opacity .35s;

				&:after, &:before {
					content: '';
					position: absolute;
					left: 50%;
					right: 50%;
					bottom: -2px;
					background: $orange;
					height: 2px;
					opacity: 0;
					transition: left .5s, right .5s, opacity .5s;
				}

				&:before {
					background: $black;
				}

			 	&.active {
			 		opacity: 1;

					&:after {
						left: 0;
						right: 0;
						opacity: 1;
					}
				}							

				&:hover {
					opacity: 1;

					&:before {
						left: 0;
						right: 0;
						opacity: 1;
					}				
				}
			}
		}
	}

	.left {
		display: inline-flex;
		align-items: center;
	}

	.links {
		margin: 0;
		padding: 0;
		margin-bottom: -5px;

		li {
			margin: 0;
			padding: 0;
		}

		li {
			list-style: none;

			a {
				text-decoration: none;
				font-size: 17px;
				line-height: 100%;
				color: #000000;	
				white-space: nowrap;
				position: relative;
				transition: color .35s;

				&:before {
					content: '';
					display: inline-block;
					vertical-align: middle;
					background-image: url(map-get($sprites, 'arrow-breadcrumbs'));
					width: 26px;
					height: 16px;
					background-size: 26px 16px;
				    margin-top: -5px;
				    transform: translateX(-25px);
				    transition: transform .5s;					
				}

				&:hover {
					color: $orange;

					&:before {
						transform: translateX(-15px);	
					}
				}			
			}
		}
	}
}

.table-tarrifs {
	width: 100%;

	thead {
		th {
			font-weight: 700;
			font-size: 21px;
			color: #000;	
			line-height: 100%;
			vertical-align: middle;
			text-align: center;
			padding-bottom: 20px;
			border-bottom: 2px #C4C4C4 solid;

			a {
				text-decoration: none;
				color: #000;
			}

			&:first-child {
				text-align: left;
			}					
		}
	}

	tbody {
		td {
			font-size: 18px;
			color: $black;
			vertical-align: middle;
			line-height: 100%;
			text-align: center;
			padding: 9px 30px;

			&:first-child {
				text-align: right;
				padding-left: 0;
			}

			&:last-child {
				padding-right: 0;
			}

			svg {
				width: 26px;
				height: 26px;
			}
		}

		.sep td {
			border-top: 1px solid #C4C4C4;
		}

		.sum td {
			background: none !important;
			padding-top: 10px;
			font-size: 21px;
			padding-bottom: 10px;
		}

		.total td {
			font-weight: bold;
			color: #000;
			font-size: 21px;
			padding-top: 10px;
			padding-bottom: 10px;			
			background: none !important;
		}

		.small td {
			font-size: 19px;
			color: #505050;
		}

		.buttons td {
			padding-top: 30px;
			background: none !important;
		}

		tr:nth-child(2n) td {
			background: #E8E8E8;
		}

		.btn {
			width: 100%;
			font-size: 15px;
		}
	}
}

body {
	.grecaptcha-badge {
		position: absolute;
		top: -99999px;
	}
}

.page-inner .s-text {
	padding-top: 70px;
}

._popup {
	padding: 120px;
	width: 40%;
	max-width: 40%;

	&-title {
		font-size: 55px;
		font-weight: bold;
		width: 50%;
		padding-bottom: 40px;
		line-height: 80%;
	}

	&-afterTitle {
		font-size: 32px;
		padding-bottom: 30px;
		line-height: 100%;
	}

	&-buttons {
		a {
			box-shadow: unset !important;
			color: #fff;
			font-weight: bold;
			font-size: 18px;
			padding: 12px 25px;

			&:hover, &:focus {
				color: #fff;
			}
		}
	}
}

.page-inner .section-case {
	margin-top: 0;
}

.section-related {
	.item-category-b .image {
		right: -150px;
	}
}
$font-family-base: 'Circe', Open Sans, Tahoma, sans-serif;
$font-family-display: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";

@font-face {
    font-family: 'Circe';
    src: url('fonts/Circe-Bold.woff2') format('woff2'),
        url('fonts/Circe-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Circe';
    src: url('fonts/Circe-Light.woff2') format('woff2'),
        url('fonts/Circe-Light.woff') format('woff');
    font-weight: 350;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Circe';
    src: url('fonts/Circe-Regular.woff2') format('woff2'),
        url('fonts/Circe-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

#viewport {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

header {
  flex: 0 0 auto;
  align-items: center;
}

main {
  flex: 1 0 auto;
  align-items: center;
}

footer {
  flex: 0 0 auto;
  align-items: center;
}

@import './vendor';
@import './styles';
@import './vendor/styler';
@import './adaptive';
@media (min-width:1700px) and (max-height:950px) {
	.section-hero {
		padding-top: 70px;
	}

	.section-hero-item .image .image-wrap {
		transform: scale(.9);
	}
}

@media (min-width:1700px) and (max-height:800px) {
	.section-hero {
		padding-top: 0px;
	}

	.section-hero-item .image .image-wrap {
		transform: scale(.82);
	}	
}

@media (max-width:1700px){
	.item-box_category .cnt-wrap .buttons .btn:first-child {
	    margin-right: 20px;
	}

	.item-box_category .cnt-wrap .buttons .btn {
		padding: 17px 22px;
		font-size: 18px;
	}

	.item-box_category .cnt-wrap .title {
		font-size: 34px;
	}

	.item-box_category .cnt-wrap p {
		font-size: 20px;
	}

	header .contact .phone {
		display: none;
	}

	header .contact {
		padding-left: 20px;
	}

	.section-hero {
		padding-top: 0;
		min-height: 700px;
	}

	.section-hero-item .image .image-wrap {
		transform: scale(.82);
	}	

	header .menu {
		padding: 0;
	}

	.section-hero-item .title {
		margin-top: -150px;
	}

	.section-hero-item .image .image-wrap {
		margin-left: -160px;
		margin-top: 50px;
	}

	.section-hero-item .title {
		font-size: 55px;
	}

	.section-hero-item .afterTitle {
		font-size: 32px;
		line-height: 90%;
	}

	.section-hero-item .image {
		margin-left: -110px;
	}

	.item-category .image {
		transform: scale(.8);
		transform-origin: bottom right;
	    right: -90px;
	    top: -170px;		
	}

	.item-category, .item-category-b {
		padding: 70px;
	}

	.item-category .name {
		font-size: 32px;
	}

	.item-category {
		height: 250px;
	}

	.s-title {
		font-size: 62px;
	}

	.section-request .afterTitle {
		font-size: 34px;
	}

	.section-request .form .this input[type=text] {
		font-size: 33px;
	}

	.item-review .head {
		flex-direction: column;
		align-items: flex-start;
	}

	.item-review {
		padding: 40px;
	}

	.item-review .view {
		margin-top: 15px;
	}

	.item-review .s-text p {
		font-size: 16px;
	}

	.item-review .image, .item-review .video {
		height: 135px;
		max-height: 135px;
	}

	.item-category-b .name {
		white-space: unset;
	}

	.item-category-b .image {
		bottom: -250px;
	}

	.section-calc .slide p {
		font-size: 24px;
	}

	.section-calc .form-floating>.form-control:not(select):not(textarea):focus, .section-calc .form-floating>.form-control:not(select):not(textarea):not(:placeholder-shown), .section-calc .form-floating>label, .section-calc .jq-selectbox__select {
	    font-size: 22px;
	}	

	.page-contact .contact .left {
		padding-left: calc(((100% - 1250px) / 2) + var(--bs-gutter-x,.75rem));
	}

	.item-box_category .cnt {
		padding: 180px;
	}

	.item-box_category .cnt.left {
		padding-left: calc(((100% - 1250px) / 2) + var(--bs-gutter-x,.75rem));
	}

	.item-box_category .cnt.right {
		padding-right: calc(((100% - 1250px) / 2) + var(--bs-gutter-x,.75rem));	
	}

	.page-contact .contact .left .this {
		flex-direction: column;
		align-items: flex-start;
	}

	.page-contact .contact .left .this .mail-wrap {
		margin-top: 15px;
	}

	.page-contact .contact .left .title {
		font-size: 40px;
	}

	.page-contact .contact .left .head p {
		font-size: 18px;
	}

	.page-contact .contact .left .this .mail {
		font-size: 23px;
	}
}

@media (max-width:1540px){
	.section-hero {
		min-height: 700px;
	}

	.section-hero-item .image .image-wrap {
		transform: scale(.75);
	}	

	.section-hero-item .title {
	    margin-top: -200px;
	}

	.section-hero-item .image .image-wrap {
		margin-left: -160px;
	}

	.section-hero-item .title {
		font-size: 52px;
	}

	.section-hero-item .afterTitle {
		font-size: 28px;
		line-height: 90%;
		width: 70%;
	}

	.item-review .image, .item-review .video {
	    height: 220px;
	    max-height: 220px;
	}

	.section-request .afterTitle {
	    font-size: 26px;
	    display: flex;
	    align-items: center;
	    padding-top: 8px;
	}	

	.s-title {
		font-size: 52px;
	}

	.section-request .form .this input[type=text] {
	    font-size: 45px;
	    text-align: left;
	}	

	.section-request .form .rules {
		text-align: left;
		justify-content: flex-start;
		padding-left: 0;
	}

	.s {
		&-padding {
			padding-top: 120px;
			padding-bottom: 120px;

			&-t {
				padding-top: 120px;
			}

			&-b {
				padding-bottom: 120px;
			}
		}	

		&-padding-m {
			padding-top: 60px;
			padding-bottom: 60px;

			&-t {
				padding-top: 60px;
			}

			&-b {
				padding-bottom: 60px;
			}
		}	

		&-padding-sm {
			padding-top: 40px;
			padding-bottom: 40px;

			&-t {
				padding-top: 40px;
			}

			&-b {
				padding-bottom: 40px;
			}
		}	
	}	

	.item-category, .item-category-b {
		padding: 60px;
	}

	.item-category .name {
		font-size: 27px;
	}

	.item-category .image {
	    transform: scale(.7);
	    right: -62px;
	    top: -186px;
	}	

	footer .menu-item ul li a {
		font-size: 19px;
	}

	.section-hero .control .link a {
		font-size: 16px;
	}

	.section-hero .control .pdf svg {
		width: 22px;
		height: 22px;
		margin-right: 8px;
	}

	.section-hero .control .pagination .swiper-pagination-bullet {
		width: 10px;
		height: 10px;
	}

	.section-category .type-1 {
		display: none;
	}

	.section-category .type-2 {
		display: flex;
	}	

	.item-advantage strong {
		width: 90%;
		font-size: 20px;
	}

	.s-title-m {
		font-size: 40px;
	}

	.item-category-b {
		height: 400px;
	}

	.item-category-b .image {
		transform: scale(.8);
		transform-origin: right bottom;
		bottom: -200px;
		right: -120px;
	}

	.item-category-b .name {
		font-size: 28px;
	}

	.page-contact .contact .left {
		padding-left: calc(((100% - 1040px) / 2) + var(--bs-gutter-x,.75rem));
	}	

	.item-box_category .cnt {
		padding: 150px;
	}

	.item-box_category .cnt.left {
		padding-left: calc(((100% - 1040px) / 2) + var(--bs-gutter-x,.75rem));
	}

	.item-box_category .cnt.right {
		padding-right: calc(((100% - 1040px) / 2) + var(--bs-gutter-x,.75rem));
	}

	.page-contact .contact .left .this {
		border-bottom: 0;
		padding-bottom: 0;
	}

	.page-contact .contact .left .feedback .form-floating {
		padding-bottom: 0;
	}

	.page-contact .contact .left .feedback, .page-contact .contact .left .this {
		padding-top: 60px;
	}

	.page-contact .contact .left, .page-contact .contact .right {
		width: 100%;
	}

	.page-inner .breadcrumbs.white {
		padding-top: 260px;
	}

	.breadcrumbs .tags {
		display: none;
	}

	.breadcrumbs .tags.hide {
		display: flex;
		flex-wrap: wrap;
	}

	.breadcrumbs .tags li {
		margin: 0 20px 0 0;
	}

	.breadcrumbs .tags li a {
		font-size: 18px;
	}

	.breadcrumbs .tags {
		margin: 30px 0 0 0px;
	}

	#send-request {
		width: 750px;
		padding: 100px;
	}

	#send-request .form-floating>.form-control:not(select):not(textarea):focus, #send-request .form-floating>.form-control:not(select):not(textarea):not(:placeholder-shown), #send-request .form-floating>label, #send-request .jq-selectbox__select {
	    font-size: 22px;
	}

	#send-request .row-bottom .btn {
	    width: 100%;
	    font-size: 21px;
	    padding: 25px 30px;
	}

	#send-request .title {
		font-size: 48px;
	}

	.item-category-b .name, .section-related-category .item-category-b .name {
		font-size: 25px;
	}

	.item-category, .item-category-b {
		padding: 50px;
	}
}

@media (max-width:1300px){
	header .bottom {
		display: none;
	}

	header, header .head .container {
		height: 100px;
	}

	.table-tarrifs {
		min-width: 1000px;

		&-wrap {
			overflow: auto;
			width: 100%;
		}
	}

	.table-tarrifs thead th,
	.table-tarrifs tbody .sum td, 
	.table-tarrifs tbody .total td {
		font-size: 18px;
	}

	.table-tarrifs tbody td {
		font-size: 16px;
		padding: 7px 20px;
	}

	.table-tarrifs tbody .small td {
		font-size: 15px;
	}

	.section-team .item b {
		font-size: 21px;
	}

	.section-team .item p {
		font-size: 16px;
	}

	header .wrap .logo, header .wrap .logo svg {
	    width: 211px;
	    height: 70px;
	}

	.section-icons .col b {
		font-size: 20px;
	}

	.section-icons .col .icon svg {
	    max-width: 45%;
	    max-height: 45%;		
	}

	.item-case .data strong {
		font-size: 30px;
	}

	.item-case .data span {
		font-size: 20px;
	}

	.section-case .item-case {
	    margin-top: 20px;
	    display: block;
	}	

	header .menu-wrap {
		display: none;
	}

	header .hamburger-wrap {
		display: flex;
		margin-right: auto;
	}

	/*	.section-hero .control .link {
		display: none;
	}*/

	.section-hero-item .image .image-wrap {
		margin-left: 0;
		margin-right: -30px;
	}

	.section-hero .control .arrows {
		transform: scale(.8);
		transform-origin: bottom right;
	}

	.section-hero .control .pagination .swiper-pagination-bullet {
		width: 8px;
		height: 8px;
	}

	.section-hero-item .image .image-wrap {
		transform: scale(.65);
	}

	.section-hero-item .afterTitle {
		font-size: 26px;
	}

	.section-hero-item .title {
		font-size: 45px;
	}

	.s-btn, .s-btn-fill {
		font-size: 18px;
	}

	.s-title {
		font-size: 52px;
	}

	.section-request .afterTitle {
		font-size: 34px;
	}

	.item-review .image, .item-review .video {
	    height: 200px;
	    max-height: 200px;
	}	

	.section-hero {
	    min-height: 630px;
	    max-height: 720px;
	}	

	.section-calc .slide p {
	    font-size: 22px;
	    width: 400px;
	}	

	.section-calc .slide .wrap {
		width: calc(100% - 400px);
	}

	.section-hero-item .image .image-wrap {
		margin-top: -70px;
	}

	.section-hero-item .title {
	    margin-top: -100px;
	}	

	.item-category-b .image {
	    transform: scale(.7);
	    bottom: -160px;
	    right: -120px;
	}	

	.item-category, .item-category-b {
		padding: 50px;
	}

	.item-category-b .name {
		font-size: 24px;
	}

	.item-category-b {
	    height: 350px;
	}

	.page-contact .contact .left {
		padding-left: calc(((100% - 900px) / 2) + var(--bs-gutter-x,.75rem));
	}		

	.item-box_category .cnt {
		padding: 100px;
	}

	.item-box_category .cnt.left {
		padding-left: calc(((100% - 900px) / 2) + var(--bs-gutter-x,.75rem));
	}

	.item-box_category .cnt.right {
		padding-right: calc(((100% - 900px) / 2) + var(--bs-gutter-x,.75rem));
	}

	.item-box_category .cnt-wrap .title {
	    font-size: 32px;
	}

	.item-box_category .image .circe {
		width: 400px;
		height: 400px;
		margin-left: -(400px/2);
		margin-top: -(400px/2);
		border: 50px solid $orange;		
	}

	.item-box_category .image.is-hover .circe {
		border: 50px solid $black;			
	}

	.item-box_category .image {
		min-height: 600px;
	}

	.item-box_category .cnt-wrap .buttons {
		flex-direction: column;
	}

	.item-box_category .cnt-wrap .buttons .btn:last-child {
		margin-top: 15px;
	}

	.breadcrumbs .left {
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
	}	

	.breadcrumbs .tags {
		margin-left: 0;
		margin-top: 50px;
	}

	.page-inner .breadcrumbs.white {
	    padding-top: 240px;
	    padding-bottom: 80px;
	}	

	.page-case .case-head .site {
		display: none;
		margin-left: 0;
		margin-top: 25px;
	}

	.page-case .case-head .site.hide {
		display: inline-flex;
	}

	.page-case .case-head .row .text p {
		font-size: 22px;
	}

	.page-case .case-head .row strong {
		font-size: 26px;
		padding-bottom: 25px;
	}

	.page-case .case-head .row p {
		font-size: 16px;
	}
}

@media (max-width:1050px){
	.s-text p, .s-text table {
	    font-size: 17px;
	    line-height: 135%;
	}

	.s-text p {
		margin-bottom: 1.5rem;
	}

	.s-link-icon {
		font-size: 17px;
	}

	.s-btn, .s-btn-fill {
		font-size: 16px;
	}

	.section-hero .control .link a {
	    font-size: 14px;
	}

	.case-head .breadcrumbs {
		margin-bottom: -100px;
	}

	.page-contact .contact .left .map-wrap {
		display: block;	
	}

	.swiper-buttons .button-prev {
    	left: -90px;
	} 

	.swiper-buttons .button-next {
    	right: -90px;
	} 

	.page-inner {
		padding-top: 100px;
	}

	footer .menu-item .title {
		font-size: 24px;
	}

	footer .menu-item ul li a {
		font-size: 17px;
	}

	footer .contact .phone {
		font-size: 28px;
		margin-left: 15px;
	}

	footer .contact .mail {
		font-size: 19px;
	}

	.item-review .tag {
		padding: 7px 15px;
		font-size: 14px;
	}

	.item-review .s-text p {
		font-size: 15px;
	}

	.section-request .afterTitle {
		font-size: 32px;
	}

	.s-title {
	    font-size: 46px;
	}	

	.s {
		&-padding {
			padding-top: 100px;
			padding-bottom: 100px;

			&-t {
				padding-top: 100px;
			}

			&-b {
				padding-bottom: 100px;
			}
		}	

		&-padding-m {
			padding-top: 60px;
			padding-bottom: 60px;

			&-t {
				padding-top: 60px;
			}

			&-b {
				padding-bottom: 60px;
			}
		}	

		&-padding-sm {
			padding-top: 40px;
			padding-bottom: 40px;

			&-t {
				padding-top: 40px;
			}

			&-b {
				padding-bottom: 40px;
			}
		}	
	}	

	.section-hero-item .image .image-wrap {
		margin-right: 0px;
	}	

	.section-hero-item .afterTitle {
		width: 100%;
	}

	header, header .wrap {
		height: 100px;
	}

	header .wrap .logo, header .wrap .logo svg {
	    width: 211px !important;
	    height: 70px !important;
	}

	.section-hero {
	    min-height: 500px;
	    max-height: 500px;
	}		

	.section-hero-item .title {
	    margin-top: -200px;
	}

	.section-hero .control {
		bottom: 30px;
	}	

	.section-hero-item .image .image-wrap {
		margin-top: -160px;
		transform: scale(.55);
	}

	.section-hero-item .title {
		font-size: 32px;
	}

	.section-hero-item .afterTitle {
		font-size: 23px;
		width: 80%;
	}

	.section-advantage .item-advantage {
		margin-top: 40px;
	}

	.section-advantage .row-list {
		margin-top: -40px;
	}

	.section-calc .form-floating>.form-control:not(select):not(textarea):focus, .section-calc .form-floating>.form-control:not(select):not(textarea):not(:placeholder-shown), .section-calc .form-floating>label, .section-calc .jq-selectbox__select {
    	font-size: 20px;
	}

	.section-calc .slide p {
	    font-size: 20px;
	    width: 360px;
	}	

	.section-calc .slide .wrap {
		width: calc(100% - 360px);
	}

	.s-title-m {
	    font-size: 30px;
	}	

	.item-category-b .name {
	    font-size: 28px;
	}		

	footer .row-bottom {
		padding-top: 70px;
		padding-bottom: 70px;
	}

	footer .row-head {
		padding-top: 70px;
	}

	footer .contact .social-wrap {
		margin-bottom: -94px;
	}

	.section-request .form .this input[type=text] {
		margin-right: 25px;
	}

	.page-contact .contact {
		flex-direction: column;
	}

	.page-contact .contact .left {
		padding-left: calc(((100% - 700px) / 2) + var(--bs-gutter-x,.75rem));
		padding-right: calc(((100% - 700px) / 2) + var(--bs-gutter-x,.75rem));
	}

	.item-box_category .cnt {
		padding: 150px;
	}

	.item-box_category .cnt.left {
		padding-left: calc(((100% - 700px) / 2) + var(--bs-gutter-x,.75rem));
	}

	.item-box_category .cnt.right {
		padding-right: calc(((100% - 700px) / 2) + var(--bs-gutter-x,.75rem));
	}

	.page-inner .breadcrumbs.white {
	    padding-top: 170px;
	}	

	.page-contact .contact .right {
		display: none;
	}

	.page-contact .contact .left {
		padding-bottom: 100px;
	}

	.page-inner .breadcrumbs.white {
	    padding-top: 250px;
	}

	.breadcrumbs .tags {
	    margin-top: 10px;
	    margin-bottom: -30px;		
	}

	.item-review .image, .item-review .video {
	    height: 140px;
		max-height: 140px;
	}	

	#send-request {
	    width: 670px;
	    padding: 80px;
	}

	#send-request .title {
	    font-size: 42px;
	}

	#send-request .row-bottom .btn {
		font-size: 19px;
	}

	.section-hero-slider .section-hero-item .title {
	    margin-top: -225px;
	}	

	.case-head .row {
		/*padding-top: 40px;*/

		.col-12 {
			margin-top: 50px;
		}
	}

	.page-case .case-head .site {
		margin-bottom: 40px;
	}

	.item-box_category .wrap {
		flex-direction: column;
	}

	.item-box_category .left, .item-box_category .right {
		width: 100%;
	}

	.item-box_category .image {
		order: 0;
	}

	.item-box_category .cnt, .item-box_category .cnt.left, .item-box_category .cnt.right {
		order: 1;
		padding: 100px;
		padding-left: calc(((100% - 700px) / 2) + var(--bs-gutter-x,.75rem));
		padding-right: calc(((100% - 700px) / 2) + var(--bs-gutter-x,.75rem));		
	}	

	.item-box_category .image .circe {
		width: 500px;
		height: 500px;
		margin-left: -(500px/2);
		margin-top: -(500px/2);
		border: 75px solid $orange;			
	}

	.item-box_category .image.is-hover .circe {
		border: 75px solid $black;			
	}	

	.item-box_category .cnt-wrap .buttons {
		flex-direction: unset;
	}

	.item-box_category .cnt-wrap .buttons .btn:last-child {
		margin-top: 0;
	}

	.section-review .wrap {
		padding: 60px;	
	}

	.section-review .data .person {
		font-size: 20px;	
	}
}

@media (max-width:800px){


	.section-related-category .item-category-b .name {
		width: 40%;
	}

	.case-head .row .col-12 {
		margin-top: 30px;	
	}

	.case-head .text {
		margin-bottom: -30px;	
	}

	.page-case .case-head .site {
		font-size: 22px;	
    	margin-bottom: 60px;
	}

	.page-inner {
	    padding-top: 200px;
	}		

	.page-inner.page-case {
		padding-top: 100px;	
	}

	.page-inner .breadcrumbs.white {
		padding-bottom: 50px;
	}

	.breadcrumbs .tags {
		margin-bottom: 0;	
	}

	.item-category .circe, .item-category-b .circe {
		display: none;
	}

	.section-advantage .row-list {
		margin-bottom: 40px;
	}

	.section-images, .section-works, .section-brandbook {
		.swiper-container {
			padding-right: 100px;
		}
	}

	.swiper-buttons .button-prev, .swiper-buttons .button-next {
    	display: none;
	} 

	.item-box_category .image .circe {
		width: 400px;
		height: 400px;
		margin-left: -(400px/2);
		margin-top: -(400px/2);
		border: 50px solid $orange;			
	}

	.item-box_category .image {
	    min-height: 500px;
	}

	.item-box_category .image.is-hover .circe {
		border: 50px solid $black;			
	}	

	.item-case .data {
		padding-top: 25px;
	}

	.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl, .page-contact .contact .left, .item-box_category .cnt, .item-box_category .cnt.left, .item-box_category .cnt.right {
	    width: 100%;
	    padding-right: var(--bs-gutter-x,3rem);
	    padding-left: var(--bs-gutter-x,3rem);
	    margin-right: auto;
	    margin-left: auto;
	}	

	.item-box_category .cnt.left, .item-box_category .cnt.right {
		padding-top: 70px;
		padding-bottom: 70px;
	}

	.page-contact .contact .left .feedback, .page-contact .contact .left .this {
	    padding-top: 40px;
	}

	.page-contact .contact .left {
		padding-bottom: 60px;
	}

	.section-hero .control {
		left: var(--bs-gutter-x,3rem);
		right: var(--bs-gutter-x,3rem);		
	}

	.s {
		&-padding {
			padding-top: 70px;
			padding-bottom: 70px;

			&-t {
				padding-top: 70px;
			}

			&-b {
				padding-bottom: 70px;
			}
		}	

		&-padding-m {
			padding-top: 40px;
			padding-bottom: 40px;

			&-t {
				padding-top: 40px;
			}

			&-b {
				padding-bottom: 40px;
			}
		}	

		&-padding-sm {
			padding-top: 20px;
			padding-bottom: 20px;

			&-t {
				padding-top: 20px;
			}

			&-b {
				padding-bottom: 20px;
			}
		}	
	}	

	footer .row-head .menu-item:last-child {
		margin-top: 40px;
	}

	.section-request .afterTitle {
		font-size: 29px;
	}

	.section-request .form .this input[type=text] {
		font-size: 39px;
	}

	.s-title {
		font-size: 42px;
	}

	.item-review .head {
		flex-direction: unset;
		align-items: center;
	}

	.item-review .image, .item-review .video {
		height: 220px;
		max-height: 220px;
	}

	.item-category-b {
	    height: 250px;
	}	

	.item-advantage p {
		font-size: 18px;
	}

	.item-advantage strong {
		margin-bottom: 10px;
	}

	footer .menu-item ul li a {
	    font-size: 19px;
	}

	.page-inner .breadcrumbs.white {
	    padding-top: 150px;
	}

	.breadcrumbs .tags {
		margin-top: 15px;
	}	

	.section-hero-item .image {
	    margin-left: 50px;
	}	

	.item-review .view {
	    margin-top: 0;
	}	

	.section-hero .control .arrows {
		margin-left: 0;
		transform-origin: center right;
	}		

	.section-hero-item .image .image-wrap {
		margin-left: -95px;
	}
}

@media (max-width:720px){
	header .btn-request {
		display: none;
	}

	#send-request {
		padding-top: 100px;
	}

	.section-request .form .this input[type=text] {
		text-align: center !important;
	}

	.section-request .form .this {
		flex-direction: column;
	}

	.section-request .form .this .btn {
		margin-top: 20px;
	}

	.section-request {
		text-align: center;
	}

	.section-request .afterTitle {
	    font-size: 26px;
	    width: 100%;
	    text-align: center;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    margin-top: 0;
	}	

	.section-request .form .rules {
	    .form-check-input {
		    width: 1em;
		    height: 1em;	    	
		    min-width: 1em;
		    min-height: 1em;
	    }
	}	

	.section-calc .slide {
	    align-items: unset;
	    flex-direction: column;
	    margin-bottom: 70px;
	}	

	.section-calc .slide .wrap {
		width: 100%;
		margin-top: 30px;
	}

	.section-calc .form-floating>.form-control:not(select):not(textarea):focus, .section-calc .form-floating>.form-control:not(select):not(textarea):not(:placeholder-shown), .section-calc .form-floating>label, .section-calc .jq-selectbox__select {
		font-size: 22px;
	}

	.s-title {
	    font-size: 36px;
	}

	.section-calc .s-btn-fill {
	    font-size: 20px;
	    padding: 20px 45px;
	    white-space: nowrap;
	}	

	.page-contact .contact .left .title {
		font-size: 35px;
	}

	.section-request .s-title {
		width: 100%;
	}	

	.s-title-m {
	    font-size: 27px;
	}	

	#send-request {
		width: 100%;
		height: 100%;
		padding: 60px;
	}

	#send-request .title {
		margin-bottom: 20px;
		font-size: 38px;
	}

	#send-request .form-floating>.form-control:not(select):not(textarea):focus, #send-request .form-floating>.form-control:not(select):not(textarea):not(:placeholder-shown), #send-request .form-floating>label, #send-request .jq-selectbox__select {
	    font-size: 20px;
	}	

	.item-case .data strong {
	    font-size: 28px;
	}

	.item-category-b .image {
		transform: scale(.65);
	}

	.item-case .data strong {
	    font-size: 24px;
	}

	.item-case .data span {
	    font-size: 18px;
	}

	.item-category-b .name, .section-related-category .item-category-b .name {
	    font-size: 22px;
	}			
}

@media (max-width:620px){
	header.is-open-menu .mobile .container .menu {
		margin-top: 20px;
	}

	header .hamburger-wrap {
		width: 50px;
		height: 50px;
		min-width: 50px;
		min-height: 50px;		
	}	

	header, header .wrap, header.minify, header.minify .wrap, header.is-open-menu .wrap {
		height: 80px;
	}

	header .mobile {
		top: 80px;
	}

	header.is-open-menu .mobile .container .menu>li a {
	    font-size: 24px;
	}	

	header .wrap .logo, header .wrap .logo svg {
	    width: 161px !important;
	    height: 56px !important;
	}

	.section-hero .control .arrows a {
	    width: 40px;
	    height: 40px;
	}

	.section-hero .control .arrows a svg {
		width: 16px;
		height: 27px;
	}	

	header .btn-request {
		display: none;
	}

	.page-case .case-head .row .text p {
    	font-size: 20px;
	} 

	.section-related-category {
		.item-category-b .image {
		    transform: scale(.5);
		    bottom: -120px;
		    right: -96px;			
		}
	}

	.section-images .row-head .wrap, .section-brandbook .row-head .wrap {
		flex-direction: column;
		align-items: unset;		

		.btn {
			margin-top: 20px;
			margin-bottom: -20px;
		}
	}

	footer .col-6 {
		width: 100%;
	}

	footer .row-head {
		display: flex;
		flex-direction: column;

		.menu {
			order: 1;
		}

		.contact {
			order: 0;
		}
	}

	footer .contact .social-wrap {
	    margin-bottom: 40px;
	    margin-top: 40px;
	    align-items: center;
	    justify-content: center;
	}

	footer .row-head .contact {
		align-items: center;
	}	

	footer .menu-item .title {
		text-align: center;
	}

	footer .menu-item ul, footer .row-bottom .col {
		display: flex;
		flex-direction: column;
		align-items: center;		
	}

	.section-calc .row-bottom .wrap {
		flex-direction: column;
		align-items: center;
	}

	.section-calc .row-bottom .rules {
		margin-top: 30px;
		padding-left: 22px;
	}

	.section-calc .s-btn-fill {
		width: 100%;
	}

	.page-contact .contact .left .this .phone {
		font-size: 30px;
	}

	.page-contact .contact .left .this .messenger li a, .page-contact .contact .left .this .messenger li svg {
		width: 32px;
		height: 32px;
	}

	.page-contact .contact .left .feedback .row-bottom .wrap, #send-request .row-bottom {
		align-items: flex-start;
		flex-direction: column;		
	}

	.page-contact .contact .left .feedback .row-bottom .rules, .page-contact .contact .left .feedback .row-bottom .btn, #send-request .row-bottom .btn {
		width: 100%;
	}

	.page-contact .contact .left .feedback .row-bottom .rules, #send-request .row-bottom .rules {
		padding-left: 24px;
		margin-top: 25px;
	}

	#send-request .row-bottom > * {
		width: 100%;
	}

	.section-hero-item .title {
		font-size: 30px;
	}

	.section-hero-item .afterTitle {
		font-size: 21px;
		margin-top: -10px;
	}

	.section-hero-item .image .image-wrap {
	    margin-right: -50px;
	    transform: scale(.42);
	   	margin-top: -180px;
	}

	.section-hero-slider .section-hero-item .title {
		width: 80%;
	}

	.section-hero-item .afterTitle {
		width: 70%;
	}

	.section-hero {
	    min-height: 450px;
	    max-height: 450px;
	}

	.section-hero-item .buttons .btn {
		font-size: 16px;
		padding: 17px 32px;
		margin-right: 10px;
	}

	.item-category-b .image {
		transform: scale(.6);
		bottom: -130px;
		right: -120px;		
	}

	.item-category, .item-category-b {
	    padding: 40px;
	}

	.item-category-b .name {
	    font-size: 25px;
	}

	.item-review .head {
		flex-direction: column;
		align-items: flex-start;
	}

	.item-review .view {
		margin-top: 20px;
	}

	#send-request {
		padding: 50px;
	}			

	#send-request .title {
	    margin-bottom: 20px;
	    font-size: 33px;
	    padding-right: 100px;
	    line-height: 80%;
	}

	#send-request .row-bottom {
		padding-top: 20px;
	}

	#send-request form .row > * {
		width: 100%;
	}

	#send-request .form-floating {
	    margin-bottom: 5px;
	}	

	.section-hero-item .btn {
		margin-top: 25px;
	}

	.s-btn-fill {
		padding: 16px 37px;
	}

	.item-box_category .cnt-wrap .title {
	    font-size: 26px;
	}

	.item-box_category .cnt-wrap p {
	    font-size: 18px;
	}	

	.s-title {
	    font-size: 30px;
	}		
}

@media (max-width:420px){
	.section-tarrifs + .section-case {
		margin-top: 0;
	}

	.page-inner.page-case {
	    padding-top: 80px;
	}	
	
	.item-case .data strong {
	    font-size: 24px;
	}

	.item-case .data span {
    	font-size: 18px;
	}

	.section-case .item-case {
		margin-top: 0;
	}

	.s-title {
	    font-size: 30px;
	}	

	.section-calc .slide p {
		font-size: 18px;
	}

	.section-calc .slide .range-slider .ui-slider-handle span {
		font-size: 16px;
	}

	.item-box_category .cnt.left, .item-box_category .cnt.right {
	    padding-top: 50px;
	    padding-bottom: 50px;
	}

	.item-box_category .cnt-wrap .buttons .btn:first-child {
		margin-right: 10px;
	}

	.item-box_category .cnt-wrap .buttons .btn {
	    padding: 16px 17px;
	    font-size: 15px;
	}	

	.item-box_category .image .circe {
		width: 250px;
		height: 250px;
		margin-left: -(250px/2);
		margin-top: -(250px/2);
		border: 40px solid $orange;		
	}

	.item-box_category .cnt-wrap .title {
	    font-size: 22px;
	}

	.item-box_category .cnt.left, .item-box_category .cnt.right {
	    padding-top: 30px;
	    padding-bottom: 30px;
	}	

	.item-box_category .image.is-hover .circe {
		border: 40px solid $black;			
	}

	.item-box_category .image {
	    min-height: 330px;
	}

	.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl, .page-contact .contact .left, .item-box_category .cnt, .item-box_category .cnt.left, .item-box_category .cnt.right {
	    width: 100%;
	    padding-right: var(--bs-gutter-x,1.5rem);
	    padding-left: var(--bs-gutter-x,1.5rem);
	    margin-right: auto;
	    margin-left: auto;
	}	

	.section-hero .control {
		left: var(--bs-gutter-x,2rem);
		right: var(--bs-gutter-x,2rem);		
	}

	.s {
		&-padding {
			padding-top: 50px;
			padding-bottom: 50px;

			&-t {
				padding-top: 50px;
			}

			&-b {
				padding-bottom: 50px;
			}
		}	

		&-padding-m {
			padding-top: 30px;
			padding-bottom: 30px;

			&-t {
				padding-top: 30px;
			}

			&-b {
				padding-bottom: 30px;
			}
		}	

		&-padding-sm {
			padding-top: 15px;
			padding-bottom: 15px;

			&-t {
				padding-top: 15px;
			}

			&-b {
				padding-bottom: 15px;
			}
		}	
	}		

	.section-calc .form-floating>.form-control:not(select):not(textarea):focus, .section-calc .form-floating>.form-control:not(select):not(textarea):not(:placeholder-shown), .section-calc .form-floating>label, .section-calc .jq-selectbox__select {
		font-size: 20px;
	}

	.page-contact .contact .left .title {
		font-size: 23px;
	}

	.page-contact .contact .left .head p {
		font-size: 16px;
	}

	.page-contact .contact .left .this .mail {
		font-size: 20px;
	}

	.page-contact .contact .left .this .contact-wrap {
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
	}

	.page-contact .contact .left .this .messenger {
		margin-top: 6px;
	}

	.page-contact .contact .left .this .mail-wrap {
		margin-top: 25px;
	}

	.page-contact .contact .left .this .messenger li a, .page-contact .contact .left .this .messenger li svg {
	    width: 36px;
	    height: 36px;
	}

	footer .contact .phone {
		font-size: 24px;
	}	

	footer .contact .messenger li a, footer .contact .messenger li svg, footer .contact .social li a, footer .contact .social li svg {
	    width: 30px;
	    height: 30px;
	}

	footer .row-head {
		padding-top: 50px;
	}	

	footer .row-bottom {
	    padding-top: 50px;
	    padding-bottom: 50px;
	}	

	.page-inner .breadcrumbs.white {
	    padding-top: 120px;
	}	

	.section-calc .slide p {
		width: 100%;
	}

	.s-title-m {
	    font-size: 23px;
	}		

	.section-hero-item .title {
		font-size: 25px;
	}

	.section-hero-item .afterTitle {
		font-size: 18px;
	}

	.item-review {
		padding: 35px;
	}

	.section-hero-item .afterTitle {
	    font-size: 17px;
	}	

	.section-hero-item .image .image-wrap {
		transform: scale(.35);
		margin-top: -230px;
		margin-left: -35px;
	}

	.section-hero-item .buttons .btn {
	    font-size: 15px;
	    padding: 14px 16px;
	}	

	.page-inner {
	    padding-top: 210px;
	}

	.breadcrumbs .tags li a {
	    font-size: 17px;
	}	

	.section-hero {
	    min-height: 370px;
	    max-height: 370px;
	}

	.section-hero.section-hero-slider {
	    min-height: 420px;
	    max-height: 420px;
	}

	.section-hero-item .btn {
	    margin-top: 25px;
	}

	.section-hero-item .title {
	    margin-top: -230px;
	}	

	.s-title-m {
	    font-size: 20px;
	}

	.item-advantage strong {
		font-size: 19px;
	}	

	.item-advantage p {
	    font-size: 17px;
	}	

	.s-title {
	    font-size: 28px;
	}

	.section-calc .s-btn-fill {
		font-size: 18px;
	}

	.item-category-b .name {
	    font-size: 22px;
	}

	.item-category, .item-category-b {
	    padding: 35px;
	}

	.item-category-b {
	    height: 260px;
	}

	.item-category-b .image {
	    transform: scale(.5);
	    bottom: -88px;
	    right: -98px;
	}	

	.item-review .cnt {
	    margin-bottom: 20px;
	    margin-top: 20px;
	}

	.item-review .image, .item-review .video {
	    height: 160px;
	    max-height: 160px;
	}

	.item-review .data {
		align-items: flex-start;
		flex-direction: column;		
	}	

	.item-review .s-text p {
	    font-size: 14px;
	}		

	.section-request .form .this input[type=text] {
		font-size: 32px;
	}	

	.section-request .form {
		margin-top: 10px;
	}

	.section-request .afterTitle {
		font-size: 24px;
	}

	#send-request {
	    padding: 40px;
	}

	#send-request .title {
		margin-bottom: 0;
	}	

	.section-hero .control .pdf svg {
		display: none;
	}

	header .contact {
		display: none;
	}

	header.is-open-menu .mobile .contact {
		display: flex;
		padding-left: 0;
	    
	    
	    padding-top: 30px;	
	}

	header.is-open-menu .mobile .contact .phone {
		display: block;
		margin-left: 10px;
		font-size: 22px;
	}
}
$hamburger-layer-color: #fff;
$hamburger-types: (elastic);
$hamburger-layer-border-radius: 0px;
$hamburger-layer-spacing: 5px;
$hamburger-layer-height: 3px;
$hamburger-layer-width: 25px;
$hamburger-hover-opacity: 1;
@import "./hamburgers/hamburgers";

$black: #000;
$orange: #E74623;

$primary: $orange;
$btn-border-width: 0;
$btn-border-radius: 0;
$input-border-radius: 0;
$input-bg: #fff;
$input-border-color: #C4C4C4; 
$input-focus-bg: #FFF;
$input-focus-border-color: #A3A3A3; 
$input-focus-box-shadow: #A3A3A3;
$input-padding-y: 28px;
$input-padding-x: 20px;
$input-border-width: 2px;

$grid-breakpoints: (
  xs: 0,
  sm: 800px,
  md: 1050px,
  lg: 1300px,
  xl: 1540px,
  xxl: 1700px
);

$container-max-widths: (
  sm: 700px,
  md: 900px,
  lg: 1040px,
  xl: 1250px,
  xxl: 1510px
);

$body-bg: #F1F1F1 !default;

@import "./bootstrap/bootstrap";
@import './animate';
@import './normalize';
@import './swiper';
@import './fancybox';